import React, { useEffect } from "react";
import { useMediaQuery } from "@uidotdev/usehooks";
import UseMetaTags from "../meta/UseMetaTags";
import images from "../constants/images";
const About = () => {
    const isMediumDevice = useMediaQuery("only screen and (max-width : 768px)");
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add("show-animation");
                }
            });
        });

        const hiddenElements = document.querySelectorAll(".hidden-animation");
        const hideElements = document.querySelectorAll(
            `
.about-header, .about-apply
            `
        );
        hiddenElements.forEach((e) => observer.observe(e));
        hideElements.forEach((e) => observer.observe(e));
    }, [])
    return (
        <>
            <UseMetaTags
                title={"Segen Consulting | About Us"}
            />
            <section className="py-[6%] lg:py-8 max-w-[100rem] mt-6 sm:mt-10 h-cover w-full mx-auto px-4 sm:px-6 lg:px-8">
                <div className="hidden-animation about-header text-center w-full">
                    <h1 className="text-[36px] leading-none text-center sm:text-[45px] md:text-[50px] lg:text-[60px] xl:text-[70px] font-bold text-primary-dark">
                        Who are we?
                    </h1>
                    <p className="mt-2 lg:mt-2 text-neutral-gray lg:mt-8 lg:max-w-[50%] mx-auto mb-8">
                        <span className="font-bold">SEGEN Consulting</span> is an excellent providers located in <span className="font-bold">Quezon City
                            Philippines</span> serving <span className="font-bold">US-based</span> clients in health care industry.
                        Offering clinical and non- clinical services. Our goal is to help
                        health care industry to provide quality of care at the bedside and
                        on the other hand providing our employees global career
                        opportunities.
                    </p>
                </div>
            </section>
            <section className="bg-primary-light">
                <div className='max-w-[100rem] py-[6%] mx-auto px-4 sm:px-6 lg:px-8 w-full'>
                    <div className='hidden-animation abroad-headers w-full'>
                        <h1 className='text-[26px] leading-none sm:text-[45px] md:text-[50px] lg:text-[60px] xl:text-[70px] font-bold text-primary-dark mb-3'>SEGEN Consulting</h1>
                        <p>An excellent provider located in <span className='font-bold'>Quezon City, Philippines</span> serving <span className='font-bold'>U.S.</span> based clients in health care industry.</p>
                    </div>
                    <div className='py-[6%] flex justify-center items-center flex-col-reverse object-cover'>
                        <img src={images.quezon} alt="banner" className='hidden-animation qc-img rounded-xl h-[200px] sm:h-[300px] w-full md:w-[400px]  lg:w-[600px] lg:h-[400px] md:mr-[35%] lg:mr-[40%] xl:mr-[50%] shadow-xl' />
                        <img src={isMediumDevice ? images.airplaneResponsive : images.airplane} alt="banner" className='hidden-animation airplane-img w-[400px]  lg:w-[600px] lg:h-[300px]' />
                        <img src={images.usa} alt="banner" className='hidden-animation usa-img rounded-xl h-[200px] sm:h-[300px] w-full md:w-[400px]  lg:w-[600px] lg:h-[400px] object-cover md:mr-[-35%] lg:mr-[-40%] xl:mr-[-50%] shadow-xl' />
                    </div>
                </div>
            </section>
            <section className='max-w-[100rem] py-[6%] mx-auto px-4 sm:px-6 lg:px-8 h-full'>
                <div>
                    <h1 className="text-[26px] leading-none sm:text-[45px] md:text-[50px] lg:text-[60px] xl:text-[70px] font-bold text-primary-dark mb-3">Settings</h1>
                    <h4 className='text-[16px] leading-[1.5rem] text-md md:text-lg md:w-[60%] text-justify mt-2'>Providing services to different healthcare settings involves offering tailored support and expertise to various types of medical and care facilities. These settings can include:
                    </h4>
                    <ul className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-12 font-semibold">
                        <li><i className="fa-solid fa-circle-check text-[20px] text-primary-dark mr-2"></i>Hospitals</li>
                        <li><i className="fa-solid fa-circle-check text-[20px] text-primary-dark mr-2"></i>Specialty Clinics</li>
                        <li><i className="fa-solid fa-circle-check text-[20px] text-primary-dark mr-2"></i>Physician practice</li>
                        <li><i className="fa-solid fa-circle-check text-[20px] text-primary-dark mr-2"></i>Hospice</li>
                        <li><i className="fa-solid fa-circle-check text-[20px] text-primary-dark mr-2"></i>Skilled Nursing Facility</li>
                        <li><i className="fa-solid fa-circle-check text-[20px] text-primary-dark mr-2"></i>Home Care Agencies</li>
                        <li><i className="fa-solid fa-circle-check text-[20px] text-primary-dark mr-2"></i>Assisted Living Facilities</li>
                        <li><i className="fa-solid fa-circle-check text-[20px] text-primary-dark mr-2"></i>Dialysis</li>
                        <li><i className="fa-solid fa-circle-check text-[20px] text-primary-dark mr-2"></i>Urgent Care</li>
                        <li><i className="fa-solid fa-circle-check text-[20px] text-primary-dark mr-2"></i>Personal Administrative Assistant for DNS, Medical Staffs, Administrators</li>
                        <li><i className="fa-solid fa-circle-check text-[20px] text-primary-dark mr-2"></i>Health Care offices</li>
                        <li><i className="fa-solid fa-circle-check text-[20px] text-primary-dark mr-2"></i>Staffing agency offices</li>
                        <li><i className="fa-solid fa-circle-check text-[20px] text-primary-dark mr-2"></i>Medical Billing Offices</li>
                        <li><i className="fa-solid fa-circle-check text-[20px] text-primary-dark mr-2"></i>Pharmaceutical companies</li>
                        <li><i className="fa-solid fa-circle-check text-[20px] text-primary-dark mr-2"></i>Laboratory Offices</li>
                        <li><i className="fa-solid fa-circle-check text-[20px] text-primary-dark mr-2"></i>Personal or individual setting</li>
                    </ul>

                </div>
            </section>
        </>
    );
};

export default About;
