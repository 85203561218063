import React from 'react'
const FAQs = () => {
    return (
        <section>

            <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">

                <div className="max-w-2xl mx-auto mb-10 lg:mb-14">
                    <h2 className="text-2xl font-bold md:text-4xl md:leading-tight text-primary-dark text-center">Frequently Ask Questions</h2>
                </div>


                <div className="max-w-2xl mx-auto divide-y divide-gray-200 dark:divide-gray-700">
                    <div className="py-8 first:pt-0 last:pb-0">
                        <div className="flex gap-x-5">
                            <svg className="flex-shrink-0 mt-1 size-6 text-neutral-gray" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#15686F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10" /><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" /><path d="M12 17h.01" /></svg>

                            <div>
                                <h3 className="md:text-lg font-semibold text-gray-800 dark:text-gray-200">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                </h3>
                                <p className="mt-1 text-neutral-gray">
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur fugit alias itaque repellendus sapiente nihil unde, quo, iure cupiditate soluta dolorem rerum velit quibusdam enim omnis blanditiis fugiat provident optio?
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="py-8 first:pt-0 last:pb-0">
                        <div className="flex gap-x-5">
                            <svg className="flex-shrink-0 mt-1 size-6 text-neutral-gray" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#15686F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10" /><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" /><path d="M12 17h.01" /></svg>

                            <div>
                                <h3 className="md:text-lg font-semibold text-gray-800 dark:text-gray-200">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                </h3>
                                <p className="mt-1 text-neutral-gray">
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur fugit alias itaque repellendus sapiente nihil unde, quo, iure cupiditate soluta dolorem rerum velit quibusdam enim omnis blanditiis fugiat provident optio?
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="py-8 first:pt-0 last:pb-0">
                        <div className="flex gap-x-5">
                            <svg className="flex-shrink-0 mt-1 size-6 text-neutral-gray" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#15686F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10" /><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" /><path d="M12 17h.01" /></svg>

                            <div>
                                <h3 className="md:text-lg font-semibold text-gray-800 dark:text-gray-200">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                </h3>
                                <p className="mt-1 text-neutral-gray">
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur fugit alias itaque repellendus sapiente nihil unde, quo, iure cupiditate soluta dolorem rerum velit quibusdam enim omnis blanditiis fugiat provident optio?
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="py-8 first:pt-0 last:pb-0">
                        <div className="flex gap-x-5">
                            <svg className="flex-shrink-0 mt-1 size-6 text-neutral-gray" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#15686F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10" /><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" /><path d="M12 17h.01" /></svg>

                            <div>
                                <h3 className="md:text-lg font-semibold text-gray-800 dark:text-gray-200">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                </h3>
                                <p className="mt-1 text-neutral-gray">
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur fugit alias itaque repellendus sapiente nihil unde, quo, iure cupiditate soluta dolorem rerum velit quibusdam enim omnis blanditiis fugiat provident optio?
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="py-8 first:pt-0 last:pb-0">
                        <div className="flex gap-x-5">
                            <svg className="flex-shrink-0 mt-1 size-6 text-neutral-gray" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#15686F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10" /><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" /><path d="M12 17h.01" /></svg>

                            <div>
                                <h3 className="md:text-lg font-semibold text-gray-800 dark:text-gray-200">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                </h3>
                                <p className="mt-1 text-neutral-gray">
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur fugit alias itaque repellendus sapiente nihil unde, quo, iure cupiditate soluta dolorem rerum velit quibusdam enim omnis blanditiis fugiat provident optio?
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="py-8 first:pt-0 last:pb-0">
                        <div className="flex gap-x-5">
                            <svg className="flex-shrink-0 mt-1 size-6 text-neutral-gray" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#15686F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10" /><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" /><path d="M12 17h.01" /></svg>

                            <div>
                                <h3 className="md:text-lg font-semibold text-gray-800 dark:text-gray-200">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                </h3>
                                <p className="mt-1 text-neutral-gray">
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur fugit alias itaque repellendus sapiente nihil unde, quo, iure cupiditate soluta dolorem rerum velit quibusdam enim omnis blanditiis fugiat provident optio?
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default FAQs