import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useMediaQuery } from "@uidotdev/usehooks";
import { useForm } from 'react-hook-form';
import "../styles/home.css"
import { Link } from 'react-router-dom'
import HeroImage from '../components/Hero-Image/HeroImage'
import HeroSmallMediumDevice from '../components/Hero-Small-Medium-Device/HeroSmallMediumDevice';
import UseMetaTags from '../meta/UseMetaTags';
import images from '../constants/images';
const Home = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [statusEmail, setStatusEmail] = useState(false);
    const [emailHeader, setEmailHeader] = useState('');
    const [errorEmail, setErrorEmail] = useState(false);
    const isLargeDevice = useMediaQuery("only screen and (max-width : 1315px)");
    const isMediumDevice = useMediaQuery("only screen and (max-width : 768px)");
    const onSubmit = async (data) => {
        setIsLoading(true);
        document.body.style.cursor = "wait";

        try {
            const response = await axios.post('/contact/send-email', data);
            setEmailHeader(response.data);
            setErrorEmail(false);
        } catch (error) {
            setEmailHeader(error.response?.data || "Something went wrong with the server. Please try again later.");
            setErrorEmail(true);
        } finally {
            setIsLoading(false);
            setStatusEmail(true);
            document.body.style.cursor = "default";
        }
    };
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add("show-animation");
                }
            });
        });

        const hiddenElements = document.querySelectorAll(".hidden-animation");
        const hideElements = document.querySelectorAll(
            `
.hero-header, .sub-hero-header, .hero-par, .hero-b, .hero-i, .banner-i, .slogan-d,
.abroad-headers, .qc-img, .airplane-img, .usa-img, .outro-i
            `
        );
        hiddenElements.forEach((e) => observer.observe(e));
        hideElements.forEach((e) => observer.observe(e));
    }, [])
    return (
        <>
            <UseMetaTags
                title="Segen Consulting | Home"
            />

            {/* HERO */}
            <section className='relative py-[6%] lg:py-8 max-w-[100rem] mt-6 sm:mt-10  w-full mx-auto px-4 sm:px-6 lg:px-8 flex flex-col gap-2 lg:flex-row'>
                <div className='w-full lg:max-w-[50%]'>
                    <h1 className='hidden-animation hero-header text-[36px] leading-none sm:text-[45px] md:text-[50px] lg:text-[60px] xl:text-[70px] font-bold text-primary-dark'>SEGEN Consulting</h1>
                    <h2 className='hidden-animation sub-hero-header text-[20px] sm:text-[30px] mt-[2%] md:text-[35px] lg:text-[40px] xl:text-[45px] font-bold text-neutral-black leading-[25px] sm:leading-[35px] md:leading-[40px] lg:leading-[50px] xl:leading[50px]'>
                        Empowering Healthcare <br />
                        From Afar
                    </h2>
                    <div className='hidden-animation hero-par w-full mt-8 text-neutral-gray lg:mt-14'>
                        <p>
                            Revolutionize your organization's health strategy with our remote health assistance services. Customized to meet your organization's  specific needs, our precise and excellent support ensures success from afar.
                        </p>
                    </div>
                    <div className='hidden-animation hero-b flex gap-10 mt-8 items-center'>
                        <Link to={"/contact"} className='text-secondary-white md:text-secondary-white md:font-light rounded-full transition-all hover:opacity-75 py-2 bg-primary-dark text-center px-[20px] text-neutral-black'>Become Our Client</Link>
                    </div>
                </div>
                <div className='hidden-animation hero-i w-full lg:max-w-[50%]'>
                    {isLargeDevice ? <HeroSmallMediumDevice /> : <HeroImage />}
                </div>
            </section>
            {/* info */}
            <section className='py-[6%] lg:py-8 lg:mb-[8%] max-w-[100rem] mt-6 xl:-mt-52  w-full mx-auto px-4 sm:px-6 lg:px-8'>
                <div className="hidden-animation banner-i w-full h-[200px]">
                    <h1 className='text-[26px] lg:max-w-[1200px] w-full leading-none sm:text-[45px] md:text-[50px] lg:text-[60px] xl:text-[70px] text-center mx-auto font-bold text-primary-dark'>Partner with us now!
                        And experience the difference in healthcare excellence.</h1>
                    <p className='text-center mt-2 lg:mt-8 text-neutral-gray lg:mt-8'>Let us be your ally in achieving exceptional patient care, regulatory compliance, and financial success.</p>
                </div>
            </section>
            {/* slogan */}
            <section className='py-[6%] lg:py-8 mt-6 sm:mt-10' id='slogan'>
                <div className="max-w-[100rem] w-full mx-auto px-4 sm:px-6 lg:px-8">
                    <div className='relative rounded-md bg-neutral-gray-light h-[500px] md:h-[700px] max-w-[600px] mx-auto lg:mx-0'>
                        <img className='absolute max-h-[100%] bottom-0 left-0 lg:left-[10%]' src={images.nurseSlogan} alt="nurse" />
                        <div className='hidden-animation slogan-d bg-white rounded-lg max-w-full p-4 md:p-10 absolute bottom-[-100px] m-2 md:bottom-[0%] md:right-[-0%] lg:right-[-55%] xl:right-[-80%] shadow-xl'>
                            <h2 className='text-primary-dark font-bold'>Our Slogan</h2>
                            <h1 className='font-bold text-md sm:text-lg md:text-xl lg:text-3xl mt-1 md:mt-2'>Coming together is a beginning. Keeping together is progress. Working together is success. - <span className='italic'>"Henry Ford"</span></h1>
                            <p className='text-neutral-gray mt-2 md:mt-8'>Partner with Segen Consulting today and experience the difference in healthcare excellence.</p>
                            <Link to={"/apply"} className='text-secondary-white mt-4 md:mt-8 block md:text-secondary-white md:font-light rounded-full transition-all hover:opacity-75 py-2 bg-primary-dark text-center px-[20px] text-neutral-black' >Apply Now</Link>
                        </div>
                    </div>
                </div>
            </section>
            {/* services */}
            <section className='py-[6%] lg:py-8 lg:mb-[8%] max-w-[100rem] mt-[30%] sm:mt-24 md:mt-10 lg:mt-24  w-full mx-auto px-4 sm:px-6 lg:px-8'>
                <h1 className='text-[36px] leading-none sm:text-[45px] md:text-[50px] lg:text-[60px] xl:text-[70px] text-center  font-bold text-primary-dark'>Our Services</h1>
                <div className='flex flex-wrap justify-center  gap-14 mt-10 md:mt-18'>
                    <div className=' bg-white p-4 lg:p-8 rounded-xl border border-neutral-gray-light w-full md:max-w-[550px] shadow-xl'>
                        <h1 className='text-[26px] leading-none sm:text-[25px] md:text-[40px] lg:text-[50px] xl:text-[60px] font-bold'>Clinical Services</h1>
                        <h4 className='text-[20px] leading-none text-2xl md:text-3xl  font-bold mt-2'>Optimized Care</h4>
                        <p className='text-neutral-gray text-[18px] leading-none sm:text-[20px] my-6'>Experience optimized care through:</p>
                        <ul className='text-neutral-gray mt-4'>
                            <li className='mt-4 flex items-center gap-4'><i className="fa-solid fa-file-medical text-[30px] text-primary-dark"></i>Thorough clinical documentation review </li>
                            <li className='mt-4 flex items-center gap-4'><i className="fa-solid fa-file-lines text-[30px] text-primary-dark"></i>Expert survey preparation</li>
                            <li className='mt-4 flex items-center gap-4'><i className="fa-solid fa-user-doctor text-[25px] text-primary-dark"></i>Comprehensive care gap analysis</li>
                            <li className='mt-4 flex items-center gap-4'><i className="fa-solid fa-hand-holding-dollar text-[20px] text-primary-dark"></i>Enhanced reimbursement strategies</li>
                            <li className='mt-4 flex items-center gap-4'><i className="fa-solid fa-arrow-up-right-dots text-[20px] text-primary-dark"></i>Maximized physician utilization</li>
                            <li className='mt-4 flex items-center gap-4'><i className="fa-solid fa-hospital-user text-[20px] text-primary-dark"></i>Efficiently managed patient visits</li>
                            <li className='mt-4 flex items-center gap-4'><i className="fa-solid fa-person-chalkboard text-[20px] text-primary-dark"></i>Clinical Health Educator</li>
                            <li className='mt-4 flex items-center gap-4'><i className="fa-solid fa-user-doctor text-[20px] text-primary-dark"></i>Health and Wellness Personal Consultant</li>
                        </ul>
                    </div>
                    <div className=' bg-white p-4 lg:p-8 rounded-xl border border-neutral-gray-light w-full md:max-w-[550px] shadow-xl'>
                        <h1 className='text-[26px] leading-none sm:text-[25px] md:text-[40px] lg:text-[50px] xl:text-[60px] font-bold'>Non-clinical Services</h1>
                        <h4 className='text-[20px] leading-none text-2xl md:text-3xl  font-bold mt-2'>Assistance Care</h4>
                        <p className='text-neutral-gray text-[18px] leading-none sm:text-[20px] my-6'>Assist in health care setting operations through:</p>
                        <ul className='text-neutral-gray mt-4'>
                            <li className='mt-4 flex items-center gap-4'><i className="fa-solid fa-diagram-successor text-[30px] text-primary-dark"></i>Office  and personal medical administrative assistant services</li>
                            <li className='mt-4 flex items-center gap-4'><i className="fa-solid fa-comment-dots text-[30px] text-primary-dark"></i>Data Entry Support</li>
                            <li className='mt-4 flex items-center gap-4'><i className="fa-solid fa-clipboard-user text-[30px] text-primary-dark"></i>Staffing coordination services
                            </li>
                            <li className='mt-4 flex items-center gap-4'><i className="fa-solid fa-calendar text-[30px] text-primary-dark"></i>Schedule and  Appointment management</li>
                            <li className='mt-4 flex items-center gap-4'><i className="fa-solid fa-eject text-[30px] text-primary-dark"></i>Customer support services
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            {/* careers */}
            {/* <section id='careers' className='py-[6%] bg-primary-light'>
                <div className='max-w-[100rem] mx-auto px-4 sm:px-6 lg:px-8'>
                    <div className="w-full bg-primary-dark rounded-xl lg:h-[400px] bg-primary-dark" id='banner'>
                    </div>
                    <div className="mt-5 lg:mt-16 grid lg:grid-cols-3 gap-8 lg:gap-12">
                        <div className="lg:col-span-1">
                            <h2 className="font-bold text-2xl md:text-3xl">
                                Here's what Segen Consulting brings to the table
                            </h2>
                            <p className="mt-2 md:mt-4 text-gray-500">
                                Empowering Healthcare Excellence, Together!
                            </p>
                        </div>
                        <div className="lg:col-span-2">
                            <div className="grid sm:grid-cols-2 gap-8 md:gap-12">

                                <div className="flex gap-x-5">
                                    <i className="fa-solid fa-user-doctor text-[30px] text-primary-dark"></i>
                                    <div className="grow">
                                        <h3 className="text-lg font-bold">
                                            Remote Nursing Excellence
                                        </h3>
                                        <p className="mt-1 text-neutral-gray text-justify sm:text-left">
                                            Our team of experienced and highly skilled remote nurses is dedicated to auditing charts, identifying gaps in care, and ensuring compliance with regulatory standards. With a keen eye for detail, our nurses elevate the quality of care provided to your patients.
                                        </p>
                                    </div>
                                </div>
                                <div className="flex gap-x-5">
                                    <i className="fa-solid fa-thumbs-up text-[30px] text-primary-dark"></i>
                                    <div className="grow">
                                        <h3 className="text-lg font-bold">
                                            Comprehensive Gap Analysis
                                        </h3>
                                        <p className="mt-1 text-neutral-gray text-justify sm:text-left">
                                            We conduct thorough reviews to identify any gaps in care delivery processes and provide actionable recommendations to bridge those gaps effectively. Our goal is to enhance patient outcomes and satisfaction while optimizing operational efficiency.
                                        </p>
                                    </div>
                                </div>
                                <div className="flex gap-x-5">
                                    <i className="fa-solid fa-chart-line text-[30px] text-primary-dark"></i>
                                    <div className="grow">
                                        <h3 className="text-lg font-bold">
                                            Survey Preparation Mastery
                                        </h3>
                                        <p className="mt-1 text-neutral-gray text-justify sm:text-left">
                                            Prepare for surveys with confidence! Segen Consulting offers comprehensive survey preparation services to ensure your organization is well-prepared to meet regulatory requirements and achieve outstanding results during inspections.
                                        </p>
                                    </div>
                                </div>
                                <div className="flex gap-x-5">
                                    <i className="fa-solid fa-sack-dollar text-[30px] text-primary-dark"></i>
                                    <div className="grow">
                                        <h3 className="text-lg font-bold">
                                            Maximized Reimbursement
                                        </h3>
                                        <p className="mt-1 text-neutral-gray text-justify sm:text-left">
                                            Our expert team works diligently to maximize reimbursement for your organization. Through meticulous chart audits, documentation review, and adherence to reimbursement guidelines, we help you secure the financial resources needed to thrive in today's healthcare landscape.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* abroad */}
            <section>
                <div className='max-w-[100rem] py-[6%] mx-auto px-4 sm:px-6 lg:px-8 w-full'>
                    <div className='hidden-animation abroad-headers w-full'>
                        <h1 className='text-[26px] leading-none sm:text-[45px] md:text-[50px] lg:text-[60px] xl:text-[70px] font-bold text-primary-dark mb-3'>SEGEN Consulting</h1>
                        <p>An excellent provider located in <span className='font-bold'>Quezon City, Philippines</span> serving <span className='font-bold'>U.S.</span> based clients in health care industry.</p>
                    </div>
                    <div className='py-[6%] flex justify-center items-center flex-col-reverse object-cover'>
                        <img src={images.quezon} alt="banner" className='hidden-animation qc-img rounded-xl h-[200px] sm:h-[300px] w-full md:w-[400px]  lg:w-[600px] lg:h-[400px] md:mr-[35%] lg:mr-[40%] xl:mr-[50%] shadow-xl' />
                        <img src={isMediumDevice ? images.airplaneResponsive : images.airplane} alt="banner" className='hidden-animation airplane-img w-[400px]  lg:w-[600px] lg:h-[300px]' />
                        <img src={images.usa} alt="banner" className='hidden-animation usa-img rounded-xl h-[200px] sm:h-[300px] w-full md:w-[400px]  lg:w-[600px] lg:h-[400px] object-cover md:mr-[-35%] lg:mr-[-40%] xl:mr-[-50%] shadow-xl' />
                    </div>
                </div>
            </section>
            {/* contact section */}
            <section className="bg-primary-light">
                <div className="max-w-[100rem] py-[6%] mx-auto px-4 sm:px-6 lg:px-8">
                    <div>
                        <h1 className="text-[26px] lg:max-w-[70%] leading-none sm:text-[45px] md:text-[50px] lg:text-[60px] xl:text-[70px] font-bold text-primary-dark">Contact us</h1>

                        <h2 className="text-[20px] mt-4 sm:text-[30px] md:text-[35px] lg:text-[40px] xl:text-[45px] font-bold text-neutral-black leading-[25px] sm:leading-[35px] md:leading-[40px] lg:leading-[50px] xl:leading[50px]">Chat to our friendly team</h2>

                        <p className="mt-3 text-neutral-gray lg:max-w-[40%]">Partner with Segen Consulting today and experience the difference in healthcare excellence. Let us be your ally in achieving exceptional patient care, regulatory compliance, and financial success. Reach out to us now to learn more about how we can support your organization's goals!</p>
                    </div>

                    <div className="grid grid-cols-1 gap-12 mt-10 lg:grid-cols-2">
                        <div className="grid grid-cols-1 gap-12 md:grid-cols-2">
                            <div>
                                <span className="inline-block text-primary-dark">
                                    <i className="fa-solid fa-envelope text-[30px]"></i>
                                </span>

                                <h2 className="mt-4 text-base font-bold">Email</h2>
                                <p className="mt-2 text-sm text-neutral-gray">Our friendly team is here to help.</p>
                                <p className="mt-2 text-sm text-neutral-gray">hr@segenhealthsolutions.com</p>
                            </div>

                            <div>
                                <span className="inline-block text-primary-dark">
                                    <i className="fa-solid fa-comment-dots text-[30px]"></i>
                                </span>

                                <h2 className="mt-4 text-base font-bold">Live chat</h2>
                                <p className="mt-2 text-sm text-neutral-gray">Our friendly team is here to help.</p>
                                <p className="mt-2 text-sm text-neutral-gray">Start new chat</p>
                            </div>

                            {/* <div>
                                <span className="inline-block text-primary-dark">
                                    <i className="fa-solid fa-location-dot text-[30px]"></i>
                                </span>

                                <h2 className="mt-4 text-base font-bold">Office</h2>
                                <p className="mt-2 text-sm text-neutral-gray">Come say hello at our office HQ.</p>
                                <p className="mt-2 text-sm text-neutral-gray">70 Waldo Avenue East rockaway NY 11518</p>
                            </div> */}

                            <div>
                                <span className="inline-block text-primary-dark">
                                    <i className="fa-solid fa-phone text-[30px]"></i>
                                </span>

                                <h2 className="mt-4 text-base font-bold">Phone</h2>
                                <p className="mt-2 text-sm text-neutral-gray">Mon-Fri from 8am to 5pm.</p>
                                <p className="mt-2 text-sm text-neutral-gray">516-201-9371</p>
                            </div>
                        </div>

                        <form onSubmit={handleSubmit(onSubmit)} className="rounded-lg bg-secondary-white p-4 lg:p-8 shadow-lg">

                            <div className="-mx-2 md:items-center md:flex">
                                <div className="flex-1 px-2">
                                    <label className="block mb-2 text-sm font-semibold">First Name</label>
                                    <input  {...register("firstName", {
                                        required: "First name is required", maxLength: {
                                            value: 30,
                                            message: "First Name should not exceed 30 characters."
                                        }
                                    })} type="text" placeholder="First Name" className="block w-full px-5 py-2.5 mt-2 placeholder-gray-400 bg-white border border-neutral-gray-light rounded-lg dark:placeholder-gray-600   focus:ring-primary-light focus:outline-none focus:ring" />
                                    {errors.firstName && <p className="text-red font-semibold text-xs mt-2">{errors.firstName.message}</p>}
                                </div>

                                <div className="flex-1 px-2 mt-4 md:mt-0">
                                    <label className="block mb-2 text-sm font-semibold">Last Name</label>
                                    <input {...register("lastName", {
                                        required: "Last name is required",
                                        maxLength: {
                                            value: 30,
                                            message: "Last Name should not exceed 30 characters."
                                        }
                                    })} type="text" placeholder="Last Name" className="block w-full px-5 py-2.5 mt-2 placeholder-gray-400 bg-white border border-neutral-gray-light rounded-lg dark:placeholder-gray-600   focus:ring-primary-light focus:outline-none focus:ring" />
                                    {errors.lastName && <p className="text-red font-semibold text-xs mt-2">{errors.lastName.message}</p>}
                                </div>
                            </div>
                            <div className="-mx-2 mt-2 md:items-center md:flex">
                                <div className="flex-1 px-2 mt-4 md:mt-0">
                                    <label className="block mb-2 text-sm font-semibold">Mobile Number</label>
                                    <input {...register("mobileNumber", {
                                        required: "Mobile number is required",
                                        pattern: {
                                            value: /^[0-9]+$/,
                                            message: "Mobile Number must be numeric only"
                                        },
                                        minLength: {
                                            value: 10,
                                            message: "Mobile Number should be at least 10 characters"
                                        },
                                        maxLength: {
                                            value: 15,
                                            message: "Mobile Number should not exceed 15 characters."
                                        }
                                    })} type="tel" placeholder="+63 2 123 4567" className="block w-full px-5 py-2.5 mt-2 placeholder-gray-400 bg-white border border-neutral-gray-light rounded-lg dark:placeholder-gray-600   focus:ring-primary-light focus:outline-none focus:ring" />
                                    {errors.mobileNumber && <p className="text-red font-semibold text-xs mt-2">{errors.mobileNumber.message}</p>}
                                </div>
                                <div className="flex-1 px-2">
                                    <label className="block mb-2 text-sm font-semibold">Email</label>
                                    <input {...register("email", {
                                        required: "Email is required",
                                        pattern: {
                                            value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                            message: "Invalid email address"
                                        },
                                        maxLength: {
                                            value: 50,
                                            message: "Email should not exceed 50 characters."
                                        }
                                    })} type="email" placeholder="email@example.com" className="block w-full px-5 py-2.5 mt-2 placeholder-gray-400 bg-white border border-neutral-gray-light rounded-lg dark:placeholder-gray-600   focus:ring-primary-light focus:outline-none focus:ring" />
                                    {errors.email && <p className="text-red font-semibold text-xs mt-2">{errors.email.message}</p>}
                                </div>
                            </div>
                            <div className="mt-4">
                                <label className="block mb-2 text-sm font-semibold">Subject</label>
                                <input {...register("subject", {
                                    required: "Subject is required", maxLength: {
                                        value: 50,
                                        message: "Email should not exceed 50 characters."
                                    }
                                })} type="text" placeholder="Subject" className="block w-full px-5 py-2.5 mt-2 placeholder-gray-400 bg-white border border-neutral-gray-light rounded-lg dark:placeholder-gray-600   focus:ring-primary-light focus:outline-none focus:ring" />
                                {errors.subject && <p className="text-red font-semibold text-xs mt-2">{errors.subject.message}</p>}
                            </div>
                            <div className="w-full mt-4">
                                <label className="block mb-2 text-sm font-semibold">Message</label>
                                <textarea  {...register("message", {
                                    required: "Message is required", maxLength: {
                                        value: 200,
                                        message: "Message should not exceed 200 characters"
                                    }
                                })} className="block w-full h-32 px-5 py-2.5 mt-2 placeholder-gray-400 bg-white border border-neutral-gray-light rounded-lg md:h-56 dark:placeholder-gray-600   focus:ring-primary-light focus:outline-none focus:ring" placeholder="Your Message..."></textarea>
                                {errors.message && <p className="text-red font-semibold text-xs mt-2">{errors.message.message}</p>}
                            </div>
                            <button disabled={isLoading} onClick={handleSubmit} className={`${isLoading && "opacity-50"} w-full px-6 py-3 mt-4 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-primary-dark rounded-full`}>
                                {isLoading ? (
                                    <>
                                        <svg aria-hidden="true" role="status" className="inline w-4 h-4 me-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                        </svg>
                                        Processing...
                                    </>
                                ) : "Send Message"}
                            </button>
                            {statusEmail && (
                                <div className={`mt-4 text-sm ${errorEmail ? 'text-red font-semibold' : 'text-green'}`}>
                                    {emailHeader}
                                </div>
                            )}

                        </form>
                    </div>
                </div>

            </section>
            {/* info */}
            <section className='py-[6%] lg:py-8 lg:mb-[8%] max-w-[100rem] mt-6 sm:mt-10 lg:mt-24  w-full mx-auto px-4 sm:px-6 lg:px-8'>
                <div className='hidden-animation outro-i mb-[20%] w-full'>
                    <h1 className='text-[26px] lg:max-w-[70%] leading-none sm:text-[45px] md:text-[50px] lg:text-[60px] xl:text-[70px] font-bold text-primary-dark'>SEGEN Consulting</h1>
                    <h3 className='w-auto mb-10 md:max-w-[600px] text-[20px] sm:text-[30px] mt-[2%] md:text-[35px] lg:text-[40px] xl:text-[45px] font-bold text-neutral-black leading-[25px] sm:leading-[35px] md:leading-[40px] lg:leading-[50px] xl:leading[50px]'>Empowering Healthcare
                        Excellence, Together!
                    </h3>
                    <Link to={"/apply"} className='text-secondary-white md:text-secondary-white md:font-light rounded-full transition-all hover:opacity-75 py-2 bg-primary-dark text-center px-[20px] text-neutral-black'>Apply Now</Link>
                </div>
            </section>
        </>
    )
}

export default Home