import React, { useState, useCallback } from 'react'
import axios from 'axios'
import { useMediaQuery } from "@uidotdev/usehooks";
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import UseMetaTags from '../meta/UseMetaTags';
const Apply = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [file, setFile] = useState('');
    const [statusEmail, setStatusEmail] = useState(false);
    const [emailHeader, setEmailHeader] = useState('');
    const [errorEmail, setErrorEmail] = useState(false);
    const [uploadError, setUploadError] = useState(false);
    const isSmallDevice = useMediaQuery("only screen and (max-width : 640px)");
    const onDrop = useCallback(async (acceptedFiles) => {
        try {
            const file = acceptedFiles[0];
            const base64Data = await fileToBase64(file);
            setUploadError(false)
            setFile(base64Data);
        } catch (error) {
            console.error('Error converting file to base64:', error);
            setUploadError(true)
        }
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const fileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => {
                resolve(reader.result.split(',')[1]);
            };

            reader.onerror = (error) => {
                reject(error);
            };
        });
    };
    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (file.type !== 'application/pdf') {
            console.error('Invalid file format. Please select a PDF file.');
            return;
        }
        try {
            const base64Data = await fileToBase64(file);
            setFile(base64Data);
        } catch (error) {
            console.error('Error converting file to base64:', error);
        }
    };
    const onSubmit = async (data) => {
        try {
            setIsLoading(true);
            document.body.style.cursor = "wait";

            const response = await axios.post('/contact/apply-email', {
                ...data,
                file,
            });

            setIsLoading(false);
            setEmailHeader(response.data);
            document.body.style.cursor = "default";
            setStatusEmail(true);
            setErrorEmail(false);
        } catch (error) {
            setIsLoading(false);
            console.error('Error sending email:', error.response?.data || error.message);
            document.body.style.cursor = "default";
            setStatusEmail(true);
            setEmailHeader(error.response?.data || "Something went wrong to the server. Please try again later.");
            setErrorEmail(true);
        }
    };
    return (
        <>
            <UseMetaTags
                title={"Segen Consulting | Apply"}
            />
            <section className='py-[6%] lg:py-8 max-w-[100rem] mt-6 sm:mt-10  w-full mx-auto px-4 sm:px-6 lg:px-8'>
                <div className='w-full md:w-[50%]'>
                    <h1 className="text-[36px] sm:text-[45px] md:text-[50px] lg:text-[60px] xl:text-[70px] font-bold leading-none text-primary-dark">Ready to work with Segen Consulting?</h1>
                    <p className="mt-2 lg:mt-2 text-neutral-gray lg:mt-8 mb-8 ">Join our supportive organization where employees are valued and empowered. We provide comprehensive support and training to help you thrive in your role and beyond.</p>
                </div>
            </section>
            <section className='py-[6%] lg:py-8 max-w-[100rem] mt-6 sm:mt-10  w-full mx-auto px-4 sm:px-6 lg:px-8 mb-24'>
                <div>
                    <div className="grid grid-cols-1 gap-12 mt-10 lg:grid-cols-2">
                        <div className="grid grid-cols-1 gap-12 md:grid-cols-2">
                            <div>
                                <span className="inline-block text-primary-dark">
                                    <i className="fa-solid fa-envelope text-[30px]"></i>
                                </span>

                                <h2 className="mt-4 text-base font-bold">Email</h2>
                                <p className="mt-2 text-sm text-neutral-gray">Our friendly team is here to help.</p>
                                <p className="mt-2 text-sm text-neutral-gray">hr@segenhealthsolutions.com</p>
                            </div>

                            <div>
                                <span className="inline-block text-primary-dark">
                                    <i className="fa-solid fa-comment-dots text-[30px]"></i>
                                </span>

                                <h2 className="mt-4 text-base font-bold">Live chat</h2>
                                <p className="mt-2 text-sm text-neutral-gray">Our friendly team is here to help.</p>
                                <p className="mt-2 text-sm text-neutral-gray">Start new chat</p>
                            </div>

                            {/* <div>
                                <span className="inline-block text-primary-dark">
                                    <i className="fa-solid fa-location-dot text-[30px]"></i>
                                </span>

                                <h2 className="mt-4 text-base font-bold">Office</h2>
                                <p className="mt-2 text-sm text-neutral-gray">Come say hello at our office HQ.</p>
                                <p className="mt-2 text-sm text-neutral-gray">70 Waldo Avenue East rockaway NY 11518</p>
                            </div> */}
                            <div>
                                <span className="inline-block text-primary-dark">
                                    <i className="fa-solid fa-phone text-[30px]"></i>
                                </span>

                                <h2 className="mt-4 text-base font-bold">Phone</h2>
                                <p className="mt-2 text-sm text-neutral-gray">Mon-Fri from 8am to 5pm.</p>
                                <p className="mt-2 text-sm text-neutral-gray">516-201-9371</p>
                            </div>
                        </div>


                        <div className="rounded-lg bg-secondary-white p-4 lg:p-8 shadow-lg">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="-mx-2 md:items-center md:flex">
                                    <div className="flex-1 px-2">
                                        <label className="block mb-2 text-sm font-semibold">First Name</label>
                                        <input {...register('firstName', {
                                            required: 'First Name is required', maxLength: {
                                                value: 30,
                                                message: 'First Name should not exceed 30 characters.'
                                            }
                                        })} type="text" placeholder="First Name" className="block w-full px-5 py-2.5 mt-2 placeholder-gray-400 bg-white border border-neutral-gray-light rounded-lg dark:placeholder-gray-600   focus:ring-primary-light focus:outline-none focus:ring" />
                                        {errors.firstName && <p className="text-red font-semibold text-xs mt-1">{errors.firstName.message}</p>}
                                    </div>

                                    <div className="flex-1 px-2 mt-4 md:mt-0">
                                        <label className="block mb-2 text-sm font-semibold">Last Name</label>
                                        <input   {...register('lastName', {
                                            required: 'Last Name is required',
                                            maxLength: {
                                                value: 30,
                                                message: 'Last Name should not exceed 30 characters.'
                                            }
                                        })} type="text" placeholder="Last Name" className="block w-full px-5 py-2.5 mt-2 placeholder-gray-400 bg-white border border-neutral-gray-light rounded-lg dark:placeholder-gray-600   focus:ring-primary-light focus:outline-none focus:ring" />
                                        {errors.lastName && <p className="text-red font-semibold text-xs mt-1">{errors.lastName.message}</p>}
                                    </div>
                                </div>
                                <div className="-mx-2 mt-2 md:items-center md:flex">
                                    <div className="flex-1 px-2 mt-4 md:mt-0">
                                        <label className="block mb-2 text-sm font-semibold">Mobile Number</label>
                                        <input  {...register('mobileNumber', {
                                            required: 'Mobile Number is required',
                                            pattern: {
                                                value: /^[0-9]+$/,
                                                message: 'Mobile Number must be numeric only',
                                            },
                                            minLength: {
                                                value: 10,
                                                message: 'Mobile Number must be at least 10 characters long',
                                            },
                                            maxLength: {
                                                value: 15,
                                                message: 'Mobile Number should not exceed 15 characters.'
                                            }
                                        })} type="tel" placeholder="+63 2 123 4567" className="block w-full px-5 py-2.5 mt-2 placeholder-gray-400 bg-white border border-neutral-gray-light rounded-lg dark:placeholder-gray-600   focus:ring-primary-light focus:outline-none focus:ring" />
                                        {errors.mobileNumber && <p className="text-red font-semibold text-xs mt-1">{errors.mobileNumber.message}</p>}
                                    </div>
                                    <div className="flex-1 px-2">
                                        <label className="block mb-2 text-sm font-semibold">Email</label>
                                        <input {...register('email', {
                                            required: 'Email is required',
                                            pattern: {
                                                value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                                message: 'Invalid email format'
                                            },
                                            maxLength: {
                                                value: 50,
                                                message: 'Email should not exceed 50 characters.'
                                            }
                                        })} type="email" placeholder="email@example.com" className="block w-full px-5 py-2.5 mt-2 placeholder-gray-400 bg-white border border-neutral-gray-light rounded-lg dark:placeholder-gray-600   focus:ring-primary-light focus:outline-none focus:ring" />
                                        {errors.email && <p className="text-red font-semibold text-xs mt-1">{errors.email.message}</p>}
                                    </div>
                                </div>
                                <div className="-mx-2 mt-2 md:flex md:justify-center">
                                    <div className="flex-1 px-2 mt-4 md:mt-0">
                                        <label className="block mb-2 text-sm font-semibold">Subject</label>
                                        <input {...register("subject", {
                                            required: "Subject is required", maxLength: {
                                                value: 50,
                                                message: "Email should not exceed 50 characters"
                                            }
                                        })} type="text" placeholder="Subject" className="block w-full px-5 py-2.5 mt-2 placeholder-gray-400 bg-white border border-neutral-gray-light rounded-lg dark:placeholder-gray-600   focus:ring-primary-light focus:outline-none focus:ring" />
                                        {errors.subject && <p className="text-red font-semibold text-xs mt-2">{errors.subject.message}</p>}
                                    </div>
                                </div>
                                <div className={`w-full mt-4 text-center cursor-pointer rounded-lg p-6 border-dashed border-2 border-neutral-gray ${isDragActive ? "bg-light-green" : "bg-neutral-gray-light"}`} {...getRootProps()}>
                                    <label className="block mb-2 text-sm font-semibold cursor-pointer">Upload Resume</label>
                                    <input {...getInputProps({ onChange: handleFileChange })} type='file' />
                                    {uploadError && <p className="text-red font-semibold text-xs mt-2">File must be in PDF format</p>}
                                    {
                                        isDragActive ?
                                            <p className="mt-2 text-sm">Drop the file here.</p> :
                                            <>
                                                {file ? (
                                                    <>
                                                        {isSmallDevice ? <><p className='text-ellipsis overflow-hidden'>{file}</p></> : <iframe
                                                            src={`data:application/pdf;base64,${file}`}
                                                            type="application/pdf"
                                                            title="Description of the iframe content"
                                                            width="100%"
                                                            height="500px"
                                                            allowFullScreen
                                                        />}
                                                        <button
                                                            onClick={() => setFile('')}
                                                            className="mt-2 text-sm text-red hover:text-red-dark focus:outline-none hover:underline"
                                                        >
                                                            Remove
                                                        </button>
                                                    </>

                                                ) : <p className="mt-2 text-sm text-neutral-gray">Drag & drop some files here, or click to select files</p>}
                                            </>
                                    }
                                </div>
                                <div className="w-full mt-4">
                                    <label className="block mb-2 text-sm font-semibold">Message</label>
                                    <textarea  {...register('message', {
                                        required: 'Message is required', maxLength: {
                                            value: 200,
                                            message: 'Message should not exceed 200 characters.'
                                        }
                                    })} className="block w-full h-32 px-5 py-2.5 mt-2 placeholder-gray-400 bg-white border border-neutral-gray-light rounded-lg md:h-56 dark:placeholder-gray-600   focus:ring-primary-light focus:outline-none focus:ring" placeholder="Your Message..."></textarea>
                                    {errors.message && <p className="text-red font-semibold text-xs mt-1">{errors.message.message}</p>}
                                </div>
                                <button disabled={isLoading} onClick={handleSubmit} className={`${isLoading && "opacity-50"} w-full px-6 py-3 mt-4 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-primary-dark rounded-full transition-all hover:opacity-75`}>
                                    {isLoading ? (
                                        <>
                                            <svg aria-hidden="true" role="status" className="inline w-4 h-4 me-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                            </svg>
                                            Processing...
                                        </>
                                    ) : "Send Message"}
                                </button>
                                {statusEmail && (
                                    <div className={`mt-4 text-sm ${errorEmail ? 'text-red font-semibold' : 'text-green'}`}>
                                        {emailHeader}
                                    </div>
                                )}
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Apply