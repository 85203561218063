import React from 'react';
import { Link } from 'react-router-dom';
const JobOffer = ({ jobOffers }) => {
    return (
        <ul className="flex flex-wrap -m-2 mb-1.5 gap-8 justify-center">
            {jobOffers.length > 0 ? (
                jobOffers.map(jobOffer => (
                    <li className='border border-neutral-gray-light shadow-xl w-[400px] bg-white p-6 rounded-xl h-max' key={jobOffer._id}>
                        <h2 className='text-[26px] font-bold'>
                            {jobOffer.title}
                        </h2>
                        <ul className='mb-6 text-neutral-gray'>
                            {jobOffer.requirements.map((requirement, index) => (
                                <li key={index} className='mt-4 flex gap-4'><i className="fa-solid fa-circle-check text-[20px] text-primary-dark"></i>{requirement}</li>
                            ))}
                        </ul>
                        <Link to={"/apply"} className='text-secondary-white md:text-secondary-white md:font-light rounded-full transition-all hover:opacity-75 py-2 bg-primary-dark text-center px-[20px] text-neutral-black'>Apply Now</Link>
                    </li>
                ))
            ) :
                <div className='border border-neutral-gray-light bg-white p-24 rounded-xl lg:text-2xl text-neutral-gray uppercase'><h1 className='font-bold'>No Job Offers Posted.</h1></div>
            }
        </ul>
    );
}

export default JobOffer;
