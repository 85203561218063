import React from 'react'
import "./HeroSmallMid.css"
import images from '../../constants/images'
const HeroSmallMediumDevice = () => {
    return (
        <>
            <div className="relative mt-8 lg:mt-0 w-full">
                <img className="w-full md:h-[400px]  object-cover rounded-lg" src={images.heroImageSmall} alt="nurse" />
            </div>


        </>
    )
}

export default HeroSmallMediumDevice