import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import JobOffer from '../components/Job-offers/JobOffer';
import JobOfferSkeleton from '../components/Job-offers/JobOfferSkeleton';
import UseMetaTags from '../meta/UseMetaTags';
const Careers = () => {
    const [jobOffers, setJobOffers] = useState([]);
    const [fetching, setFetching] = useState(false);
    useEffect(() => {
        fetchJobOffers();
    }, []);

    const fetchJobOffers = async () => {
        try {
            setFetching(true)
            const response = await axios.get('/job/all-offers')
            setJobOffers(response?.data);
            setTimeout(() => {
                setFetching(false);
            }, 1000)
        } catch (error) {
            setFetching(false)
            console.error('Error fetching job offers:', error);
        }
    };
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add("show-animation");
                }
            });
        });

        const hiddenElements = document.querySelectorAll(".hidden-animation");
        const hideElements = document.querySelectorAll(
            `
.careers-header, .careers-apply, .outro-i
            `
        );
        hiddenElements.forEach((e) => observer.observe(e));
        hideElements.forEach((e) => observer.observe(e));
    }, [])
    return (
        <>
            <UseMetaTags
                title={"Segen Consulting | Job Openings"}
            />
            <section className="py-[6%] lg:py-8 max-w-[100rem] mt-6 sm:mt-10  w-full mx-auto px-4 sm:px-6 lg:px-8">
                <div className="relative z-10">
                    <div className="hidden-animation careers-header w-full">
                        <h1 className="text-[36px] leading-none sm:text-[45px] md:text-[50px] lg:text-[60px] xl:text-[70px] font-bold text-primary-dark">Join with us</h1>
                        <h2 className="text-[20px] mt-4 sm:text-[30px] md:text-[35px] lg:text-[40px] xl:text-[45px] font-bold">Job Offers</h2>
                        <p className="mb-20 text-neutral-gray font-medium leading-relaxed md:max-w-md">Let us be your ally in achieving exceptional patient care, regulatory compliance, and financial success.</p>
                    </div>
                    {fetching ? <JobOfferSkeleton /> : <JobOffer jobOffers={jobOffers} />}
                </div>
            </section>
            <section className='bg-primary-light py-[6%] mx-auto px-4 sm:px-6 lg:px-8 h-[80vh] grid text-center mt-24 gap-4 place-content-center'>
                <div className='hidden-animation careers-apply w-full text-left md:text-center'>
                    <h1 className='text-[26px] leading-none sm:text-[45px] md:text-[50px] lg:text-[60px] xl:text-[70px] mx-auto font-bold '>Apply now for future opportunities!</h1>
                    <p className=' mt-2 lg:mt-8 text-neutral-gray lg:mt-8 lg:max-w-[60%] mx-auto mb-8'>Can't find the job you want? No worries! Simply submit your resume and we'll reach out as soon as your ideal opportunity becomes available.</p>
                    <Link to={"/apply"} className='text-secondary-white md:text-secondary-white md:font-light rounded-full transition-all hover:opacity-75 py-2 bg-primary-dark text-center px-[20px] text-neutral-black'>Send Resume</Link>
                </div>
            </section>
            {/* info */}
            <section className='py-[6%] lg:py-8 lg:mb-[8%] max-w-[100rem] mt-6 sm:mt-10 lg:mt-24  w-full mx-auto px-4 sm:px-6 lg:px-8'>
                <div className='hidden-animation outro-i mb-[20%] w-full'>
                    <h1 className='text-[26px] lg:max-w-[70%] leading-none sm:text-[45px] md:text-[50px] lg:text-[60px] xl:text-[70px] font-bold text-primary-dark'>SEGEN Consulting</h1>
                    <h3 className='w-auto mb-10 md:max-w-[600px] text-[20px] sm:text-[30px] mt-[2%] md:text-[35px] lg:text-[40px] xl:text-[45px] font-bold text-neutral-black leading-[25px] sm:leading-[35px] md:leading-[40px] lg:leading-[50px] xl:leading[50px]'>Empowering Healthcare
                        Excellence, Together!
                    </h3>
                    <Link to={"/apply"} className='text-secondary-white md:text-secondary-white md:font-light rounded-full transition-all hover:opacity-75 py-2 bg-primary-dark text-center px-[20px] text-neutral-black'>Apply Now</Link>
                </div>
            </section>
        </>
    )
}

export default Careers