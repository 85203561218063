import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useUser } from '../context/useContext'
import "../styles/admin-create-account.css"
import TableSkeleton from '../components/TableSkeleton/TableSkeleton'
const AdminCreateAccount = () => {
    const navigate = useNavigate();
    const { user } = useUser();
    const [username, setUsername] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [cpassword, setCPassword] = useState("")
    const [isLoading, setIsLoading] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [admin, setAdmin] = useState();

    const handleModal = () => {
        setIsModal(!isModal);
    }
    useEffect(() => {
        const verifyUser = () => {
            if (user && user.role !== "super_admin") {
                navigate("/sign-in-as-admin");
            }
        }
        verifyUser();
    }, [navigate, user])
    const fetchData = async () => {
        try {
            const data = (await axios.get("/admin/all-users")).data;
            setAdmin(data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);
    const handleDelete = async (id) => {
        try {
            await axios.delete(`/admin/delete-account/${id}`);
            fetchData()
        } catch (error) {
            console.log(error);
        }
    }
    const handleRoleChange = async (id, newRole) => {
        try {
            await axios.put(`/admin/change-role`, { id, newRole });
            fetchData();
        } catch (error) {
            console.log(error);
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== cpassword) {
            console.log("Please match your password")
        } else {
            try {
                setIsLoading(true);
                await axios.post('/auth/register', {
                    username,
                    email,
                    password
                }, { withCredentials: true })
                setIsLoading(false);
                setIsModal(!isModal);
                fetchData();
            } catch (error) {
                console.log(error)
                setIsLoading(false);
            }
        }
    }
    return (
        <>
            {isModal && <div className='fixed w-screen h-screen flex justify-center items-center'>
                <div className={`h-[100vh] w-[100vw] fixed top-0 left-0 bg-white backdrop-filter backdrop-blur-lg bg-opacity-30`} onClick={handleModal}>
                </div>
                <div className="relative rounded-lg bg-secondary-white p-4 lg:p-8 shadow-lg lg:w-1/3 z-30">
                    <div className='w-[30px] h-[30px] absolute cursor-pointer -top-4 -right-4 bg-neutral-gray rounded-full flex justify-center items-center z-40' onClick={handleModal}>
                        <i className="fa-solid fa-xmark text-xl text-white"></i>
                    </div>
                    <h1 className="text-center text-4xl font-bold">Admin Create Account</h1>
                    <div className="mt-4">
                        <label className="block mb-2 text-sm font-semibold">Username</label>
                        <input required type="text" onChange={(e) => { setUsername(e.target.value) }} placeholder="Username" className="block w-full px-5 py-2.5 mt-2 placeholder-gray-400 bg-white border border-neutral-gray-light rounded-lg dark:placeholder-gray-600   focus:ring-primary-light focus:outline-none focus:ring" />
                    </div>
                    <div className="mt-4">
                        <label className="block mb-2 text-sm font-semibold">Email</label>
                        <input required type="email" onChange={(e) => { setEmail(e.target.value) }} placeholder="Email" className="block w-full px-5 py-2.5 mt-2 placeholder-gray-400 bg-white border border-neutral-gray-light rounded-lg dark:placeholder-gray-600   focus:ring-primary-light focus:outline-none focus:ring" />
                    </div>
                    <div className="mt-4">
                        <label className="block mb-2 text-sm font-semibold">Password</label>
                        <input required onChange={(e) => { setPassword(e.target.value) }} type="password" placeholder="Password" className="block w-full px-5 py-2.5 mt-2 placeholder-gray-400 bg-white border border-neutral-gray-light rounded-lg dark:placeholder-gray-600   focus:ring-primary-light focus:outline-none focus:ring" />
                    </div>
                    <div className="mt-4">
                        <label className="block mb-2 text-sm font-semibold">Confirm Password</label>
                        <input required onChange={(e) => { setCPassword(e.target.value) }} type="password" placeholder="Password" className="block w-full px-5 py-2.5 mt-2 placeholder-gray-400 bg-white border border-neutral-gray-light rounded-lg dark:placeholder-gray-600   focus:ring-primary-light focus:outline-none focus:ring" />
                    </div>

                    <button disabled={isLoading} onClick={handleSubmit} className={`${isLoading && "opacity-50"} w-full px-6 py-3 mt-4 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-primary-dark rounded-full`}>
                        {isLoading ? (
                            <>
                                <svg aria-hidden="true" role="status" className="inline w-4 h-4 me-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                </svg>
                                Processing...
                            </>
                        ) : "Create Account"}
                    </button>
                </div>
            </div>}
            <section className='w-full h-full p-6'>
                {!isModal && <button onClick={handleModal} className='block mx-auto bg-primary-dark py-3 px-6 font-semibold uppercase transition-all text-white hover:bg-primary-light hover:text-black rounded-xl'>Create Account</button>}
                <div className='mt-12'>
                    {admin ? <table>
                        <thead>
                            <tr>
                                <th>Username</th>
                                <th>Email</th>
                                <th>Role</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {admin?.map(user => (
                                <tr key={user._id}>
                                    <td>{user.username}</td>
                                    <td>{user.email}</td>
                                    <td>
                                        <select
                                            value={user.role}
                                            onChange={(e) => handleRoleChange(user._id, e.target.value)}
                                        >
                                            <option value="admin">Admin</option>
                                            <option value="super_admin">Super Admin</option>
                                        </select>
                                    </td>
                                    <td className='gap-2 flex justify-center'>
                                        <button
                                            className='rounded-xl px-6 bg-red'
                                            onClick={() => handleDelete(user._id)}
                                        >
                                            Delete
                                        </button>
                                        <button
                                            className='rounded-xl px-6 bg-green text-nowrap'
                                            onClick={() => handleRoleChange(user._id)}
                                        >
                                            Edit
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table> : <TableSkeleton />}
                </div>

            </section>
            {/* {statusEmail && <ModalMessage color={errorEmail ? "text-red" : "text-green"} icon={errorEmail ? (<i className="fa-solid fa-triangle-exclamation text-red"></i>) : (<i className="fa-solid fa-check text-green"></i>)} message={emailHeader} openModal={statusEmail} />} */}
        </>
    )
}

export default AdminCreateAccount