import React from 'react'
import { Link } from 'react-router-dom'
const NoPage = () => {
    return (
        <div class="relative py-[6%] lg:py-8 max-w-[100rem] my-6 sm:my-10 mx-auto w-full">
            <div class="w-full lg:w-1/2 flex flex-col items-center justify-center text-center mx-auto">
                <p class="text-7xl md:text-8xl lg:text-9xl font-bold tracking-wider">404</p>
                <p class="text-4xl md:text-5xl lg:text-6xl font-bold tracking-wider mt-2">Page Not Found</p>
                <p class="text-lg md:text-xl lg:text-2xl text-gray-500 my-12">Sorry, the page you are looking for could not be found.</p>
                <Link to={-1} class="font-semibold text-secondary-white md:font-light rounded-full transition-all hover:opacity-75 py-2 bg-primary-dark text-center px-[20px]" title="Return Home">
                    <span>Return Home</span>
                </Link>
            </div>
        </div>
    )
}

export default NoPage