import React, { useState, useEffect } from 'react';
import axios from 'axios';
import JobOfferSkeleton from '../components/Job-offers/JobOfferSkeleton';
const AdminDashboardViewer = () => {
    const [jobOffers, setJobOffers] = useState([]);
    const [formData, setFormData] = useState({
        title: '',
        requirements: [],
    });
    const [selectedOfferId, setSelectedOfferId] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [isAdding, setIsAdding] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleModal = () => {
        setIsModal(!isModal);
    }
    useEffect(() => {
        fetchJobOffers();
    }, []);

    const fetchJobOffers = async () => {
        try {
            setLoading(true);
            const response = await axios.get('/job/all-offers')
            setJobOffers(response.data);
        } catch (error) {
            console.error('Error fetching job offers:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleChange = e => {
        if (e.target.name === "requirements") {
            const requirementsArray = e.target.value.split('\n').map(requirement => requirement.trim());
            setFormData({ ...formData, requirements: requirementsArray });
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        }
    };

    const addRequirement = () => {
        setFormData({ ...formData, requirements: [...formData.requirements, ""] });
    };

    const removeRequirement = index => {
        const updatedRequirements = [...formData.requirements];
        updatedRequirements.splice(index, 1);
        setFormData({ ...formData, requirements: updatedRequirements });
    };

    const handleSubmit = e => {
        e.preventDefault();
        if (selectedOfferId) {
            setIsUpdating(true);
            axios.put(`/job/update-offer/${selectedOfferId}`, formData)
                .then(() => {
                    setFormData({
                        title: '',
                        requirements: [],
                    });
                    setSelectedOfferId(null);
                    fetchJobOffers();
                })
                .catch(error => {
                    console.error('Error updating job offer:', error);
                });
            setIsUpdating(false);
            setIsEditing(false);
            setIsModal(!isModal);
        } else {
            setIsAdding(true)
            axios.post('/job/create-offer', formData)
                .then(() => {
                    setFormData({
                        title: '',
                        requirements: [],
                    });
                    fetchJobOffers();
                })
                .catch(error => {
                    console.error('Error adding job offer:', error);
                });
            setIsAdding(false);
            setIsModal(!isModal);
        }
    };

    const handleDelete = id => {
        setIsDeleting(true);
        axios.delete(`/job/delete-offer/${id}`)
            .then(() => {
                fetchJobOffers();
            })
            .catch(error => {
                console.error('Error deleting job offer:', error);
            });
        setIsDeleting(false);
    };

    const handleEdit = offer => {
        setIsModal(!isModal);
        setSelectedOfferId(offer._id);
        setFormData({
            title: offer.title,
            requirements: offer.requirements,
        });
        setIsEditing(true);
    };
    const handleCancel = () => {
        setFormData({ title: '', requirements: [] });
        setSelectedOfferId(null);
        setIsEditing(false);
        setIsModal(!isModal);
    };
    return (
        <>
            {isModal && <div className='fixed w-screen h-screen flex justify-center items-center'>
                <div className={`h-[100vh] w-[100vw] fixed top-0 left-0 bg-white backdrop-filter backdrop-blur-lg bg-opacity-30`} onClick={handleModal}>
                </div>

                <div className='w-full lg:w-1/3 relative'>
                    <div className='w-[30px] h-[30px] absolute cursor-pointer -top-4 -right-4 bg-neutral-gray rounded-full flex justify-center items-center z-40' onClick={handleModal}>
                        <i className="fa-solid fa-xmark text-xl text-white"></i>
                    </div>

                    <div className='relative rounded-lg h-auto max-h-[500px] top-0 left-0 bg-primary-light p-4 shadow-lg z-30 overflow-y-scroll overflow-x-hidden'>
                        <h1 className='text-lg font-semibold'>Job Offers</h1>
                        <form onSubmit={handleSubmit} className='flex flex-col'>
                            <input
                                type="text"
                                name="title"
                                placeholder="Title"
                                value={formData.title}
                                onChange={handleChange}
                                className='block w-full px-5 py-2.5 mt-2 placeholder-gray-400 bg-white border border-neutral-gray-light rounded-lg dark:placeholder-gray-600 focus:ring-primary-light focus:outline-none focus:ring'
                            />
                            <div className="flex flex-col mt-2 gap-2">
                                {formData.requirements.map((requirement, index) => (
                                    <div key={index} className="">
                                        <div className="flex gap-2">
                                            <input placeholder='Requirements'
                                                type="text"
                                                value={requirement}
                                                onChange={e => {
                                                    const updatedRequirements = [...formData.requirements];
                                                    updatedRequirements[index] = e.target.value;
                                                    setFormData({ ...formData, requirements: updatedRequirements });
                                                }}
                                                className='block w-full px-5 py-2.5 placeholder-gray-400 bg-white border border-neutral-gray-light rounded-lg dark:placeholder-gray-600 focus:ring-primary-light focus:outline-none focus:ring'
                                            />
                                            <button
                                                type="button"
                                                onClick={() => removeRequirement(index)}
                                                className="px-3 py-2 bg-red text-white rounded-lg"
                                            >
                                                Remove
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="flex flex-col md:flex-row md:gap-4 md:flex-wrap">
                                <button type="button" onClick={addRequirement} className="mt-4 text-white md:text-secondary-white md:font-light rounded-full transition-all hover:opacity-75 py-2 bg-primary-dark md:bg-primary-dark px-[20px] text-neutral-black md:mx-auto">Add Requirement</button>
                                <button type="submit" disabled={isUpdating || isAdding} className='mt-4 text-white md:text-secondary-white md:font-light rounded-full transition-all hover:opacity-75 py-2 bg-primary-dark md:bg-primary-dark px-[20px] text-neutral-black md:mx-auto'>
                                    {selectedOfferId ? (isUpdating ? 'Processing...' : 'Update Job Offer') : 'Add Job Offer'}
                                </button>

                                {isEditing && <button type="button" onClick={handleCancel} className="mt-4 text-white md:text-secondary-white md:font-light rounded-full transition-all hover:opacity-75 py-2 bg-red  px-[20px] text-neutral-black md:mx-auto">Cancel</button>}
                            </div>
                        </form>
                    </div>
                </div>
            </div>}

            <section className="w-full h-full py-6">
                {!isModal && <button onClick={handleModal} className='block mx-auto bg-primary-dark py-3 px-6 font-semibold uppercase transition-all text-white hover:bg-primary-light hover:text-black rounded-xl'>Create Job Offer</button>}
                <ul className={`flex flex-wrap gap-8 justify-center h-full w-full p-4`}>
                    {jobOffers.length > 0 ? (
                        jobOffers.map(jobOffer => (
                            <li className='border border-neutral-gray-light w-[400px] bg-white p-6 rounded-xl h-max' key={jobOffer._id}>
                                <h2 className='text-[26px] font-bold'>
                                    {jobOffer.title}
                                </h2>
                                <ul className='mb-6 text-neutral-gray'>
                                    {jobOffer.requirements.map((requirement, index) => (
                                        <li key={index} className='mt-4 flex gap-4'><i className="fa-solid fa-circle-check text-[20px] text-primary-dark"></i>{requirement}</li>
                                    ))}
                                </ul>
                                <div className='flex gap-4'>
                                    <button className='text-secondary-white rounded-full transition-all hover:opacity-75 py-2 bg-primary-dark px-[30px]' onClick={() => handleEdit(jobOffer)}>Edit</button>
                                    <button className='text-secondary-white rounded-full transition-all hover:opacity-75 py-2 bg-red px-[30px]' disabled={isDeleting} onClick={() => handleDelete(jobOffer._id)}>{isDeleting ? "Processing..." : "Delete"}</button>
                                </div>
                            </li>
                        ))
                    ) : <div>{loading ? <JobOfferSkeleton /> : <h1 className='font-bold'>No Job Offers Posted.</h1>}</div>}
                </ul>
            </section>
        </>
    );
};

export default AdminDashboardViewer;
