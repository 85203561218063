import React from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";

const LogoutButton = () => {
    const navigate = useNavigate();
    const [, removeCookie] = useCookies(["segen-tk"]);

    const handleLogout = async () => {
        try {
            const response = await axios.get("/auth/logout", { withCredentials: true });


            if (response.status === 200) {
                localStorage.clear();
                removeCookie("segen-tk", { path: "/" });
                navigate("/");
                window.location.reload();
            } else {
                console.error("Error logging out:", response.data.message);
            }
        } catch (error) {
            console.error("Error logging out:", error);
        }
    };

    return (
        <button
            className="bg-primary-dark text-white hover:text-black mt-2 rounded-full py-2 hover:bg-primary-light transition-all"
            onClick={handleLogout}
        >
            <i className="fa-solid fa-right-from-bracket" title="Log Out"></i>
        </button>
    );
};

export default LogoutButton;
