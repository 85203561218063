import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import Careers from "./pages/Careers";
import Home from "./pages/Home";
import Layout from "./Layout/Layout";
import NoPage from "./pages/NoPage";
import Contact from "./pages/Contact";
import AdminDashboardViewer from "./pages/AdminDashboardViewer";
import Apply from "./pages/Apply";
import About from "./pages/About";
import SignInAdmin from "./pages/SignInAdmin";
import FAQs from "./pages/FAQs";
import ScrollToTopButton from "./components/up-button/ScrollToTopButton";
import AdminCreateAccount from "./pages/AdminCreateAccount";
import { UserProvider } from "./context/useContext";
import AdminLayout from "./Layout/AdminLayout";

function App() {
  return (
    <div className="App bg-bg-color">
      <HelmetProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="/careers" element={<Careers />} />
              <Route path="/FAQs" element={<FAQs />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/apply" element={<Apply />} />
              <Route path="/sign-in-as-admin" element={<SignInAdmin />} />
              <Route path="*" element={<NoPage />} />
            </Route>
            <Route path="/" element={<AdminLayout />}>
              <Route element={<UserProvider />}>
                <Route path="/admin" element={<AdminDashboardViewer />} />
                <Route
                  path="/admin-create-account"
                  element={<AdminCreateAccount />}
                />
              </Route>
            </Route>
          </Routes>
        </BrowserRouter>
      </HelmetProvider>
      <ScrollToTopButton />
    </div>
  );
}

export default App;
