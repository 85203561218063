import nurse1 from "../assets/hero-nurses/nurse1.webp";
import nurse2 from "../assets/hero-nurses/nurse2.webp";
import nurse3 from "../assets/hero-nurses/nurse3.webp";
import nurse4 from "../assets/hero-nurses/nurse4.webp";
import nurse5 from "../assets/hero-nurses/nurse5.webp";
import nurse7 from "../assets/hero-nurses/nurse7.webp";
import airplane from "../assets/airplane.webp";
import airplaneResponsive from "../assets/small-device-airplane.webp";
import typing from "../assets/typing.webp";
import nurseSlogan from "../assets/nurse-slogan-nobg.webp";
import quezon from "../assets/quezon.webp";
import usa from "../assets/usa.webp";
import heroImageSmall from "../assets/heroSmallDevice.webp";
import segenLogo from "../assets/segen-logo.webp";

const assets = {
  nurse1,
  nurse2,
  nurse3,
  nurse4,
  nurse5,
  nurse7,
  airplane,
  airplaneResponsive,
  typing,
  nurseSlogan,
  quezon,
  usa,
  heroImageSmall,
  segenLogo,
};
export default assets;
