import React from 'react'

const JobOfferSkeleton = () => {
    return (
        <div className='flex flex-wrap -m-2 mb-1.5 gap-8 justify-center'>
            <div className='border border-neutral-gray-light w-[400px] bg-white p-6 rounded-xl animate-pulse flex flex-col gap-2'>
                <div className='w-[300px] p-4 bg-neutral-gray rounded-full'></div>
                <div className='w-[200px] p-2 mt-2 bg-neutral-gray rounded-full'></div>
                <div className='w-[200px] p-2 mt-2 bg-neutral-gray rounded-full'></div>
                <div className='w-[200px] p-2 mt-2 bg-neutral-gray rounded-full'></div>
                <div className='w-[150px] p-4 mt-4 bg-primary-dark rounded-full'></div>
            </div>
            <div className='border border-neutral-gray-light w-[400px] bg-white p-6 rounded-xl animate-pulse flex flex-col gap-2'>
                <div className='w-[300px] p-4 bg-neutral-gray rounded-full'></div>
                <div className='w-[200px] p-2 mt-2 bg-neutral-gray rounded-full'></div>
                <div className='w-[200px] p-2 mt-2 bg-neutral-gray rounded-full'></div>
                <div className='w-[200px] p-2 mt-2 bg-neutral-gray rounded-full'></div>
                <div className='w-[150px] p-4 mt-4 bg-primary-dark rounded-full'></div>
            </div>
            <div className='border border-neutral-gray-light w-[400px] bg-white p-6 rounded-xl animate-pulse flex flex-col gap-2'>
                <div className='w-[300px] p-4 bg-neutral-gray rounded-full'></div>
                <div className='w-[200px] p-2 mt-2 bg-neutral-gray rounded-full'></div>
                <div className='w-[200px] p-2 mt-2 bg-neutral-gray rounded-full'></div>
                <div className='w-[200px] p-2 mt-2 bg-neutral-gray rounded-full'></div>
                <div className='w-[150px] p-4 mt-4 bg-primary-dark rounded-full'></div>
            </div>
            <div className='border border-neutral-gray-light w-[400px] bg-white p-6 rounded-xl animate-pulse flex flex-col gap-2'>
                <div className='w-[300px] p-4 bg-neutral-gray rounded-full'></div>
                <div className='w-[200px] p-2 mt-2 bg-neutral-gray rounded-full'></div>
                <div className='w-[200px] p-2 mt-2 bg-neutral-gray rounded-full'></div>
                <div className='w-[200px] p-2 mt-2 bg-neutral-gray rounded-full'></div>
                <div className='w-[150px] p-4 mt-4 bg-primary-dark rounded-full'></div>
            </div>
            <div className='border border-neutral-gray-light w-[400px] bg-white p-6 rounded-xl animate-pulse flex flex-col gap-2'>
                <div className='w-[300px] p-4 bg-neutral-gray rounded-full'></div>
                <div className='w-[200px] p-2 mt-2 bg-neutral-gray rounded-full'></div>
                <div className='w-[200px] p-2 mt-2 bg-neutral-gray rounded-full'></div>
                <div className='w-[200px] p-2 mt-2 bg-neutral-gray rounded-full'></div>
                <div className='w-[150px] p-4 mt-4 bg-primary-dark rounded-full'></div>
            </div>
            <div className='border border-neutral-gray-light w-[400px] bg-white p-6 rounded-xl animate-pulse flex flex-col gap-2'>
                <div className='w-[300px] p-4 bg-neutral-gray rounded-full'></div>
                <div className='w-[200px] p-2 mt-2 bg-neutral-gray rounded-full'></div>
                <div className='w-[200px] p-2 mt-2 bg-neutral-gray rounded-full'></div>
                <div className='w-[200px] p-2 mt-2 bg-neutral-gray rounded-full'></div>
                <div className='w-[150px] p-4 mt-4 bg-primary-dark rounded-full'></div>
            </div>
        </div>
    )
}

export default JobOfferSkeleton