import React from 'react'

const TableSkeleton = () => {
    return (
        <>
            <table>
                <thead>
                    <tr>
                        <th><div className='h-4 w-[100px] animate-pulse bg-neutral-gray rounded-full'></div></th>
                        <th><div className='h-4 w-full animate-pulse bg-neutral-gray rounded-full'></div></th>
                        <th><div className='h-4 w-[100px] animate-pulse bg-neutral-gray rounded-full'></div></th>
                        <th><div className='h-4 w-full animate-pulse bg-neutral-gray rounded-full'></div></th>
                    </tr>
                </thead>
                <tbody>

                    <tr>
                        <td><div className='h-4 w-full animate-pulse bg-neutral-gray-light rounded-full'></div></td>
                        <td><div className='h-4 w-full animate-pulse bg-neutral-gray-light rounded-full'></div></td>
                        <td><div className='h-4 w-full animate-pulse bg-neutral-gray-light rounded-full'></div></td>
                        <td><div className='h-4 w-full animate-pulse bg-neutral-gray-light rounded-full'></div></td>
                    </tr>
                    <tr>
                        <td><div className='h-4 w-full animate-pulse bg-neutral-gray-light rounded-full'></div></td>
                        <td><div className='h-4 w-full animate-pulse bg-neutral-gray-light rounded-full'></div></td>
                        <td><div className='h-4 w-full animate-pulse bg-neutral-gray-light rounded-full'></div></td>
                        <td><div className='h-4 w-full animate-pulse bg-neutral-gray-light rounded-full'></div></td>
                    </tr>
                    <tr>
                        <td><div className='h-4 w-full animate-pulse bg-neutral-gray-light rounded-full'></div></td>
                        <td><div className='h-4 w-full animate-pulse bg-neutral-gray-light rounded-full'></div></td>
                        <td><div className='h-4 w-full animate-pulse bg-neutral-gray-light rounded-full'></div></td>
                        <td><div className='h-4 w-full animate-pulse bg-neutral-gray-light rounded-full'></div></td>
                    </tr>
                    <tr>
                        <td><div className='h-4 w-full animate-pulse bg-neutral-gray-light rounded-full'></div></td>
                        <td><div className='h-4 w-full animate-pulse bg-neutral-gray-light rounded-full'></div></td>
                        <td><div className='h-4 w-full animate-pulse bg-neutral-gray-light rounded-full'></div></td>
                        <td><div className='h-4 w-full animate-pulse bg-neutral-gray-light rounded-full'></div></td>
                    </tr>
                    <tr>
                        <td><div className='h-4 w-full animate-pulse bg-neutral-gray-light rounded-full'></div></td>
                        <td><div className='h-4 w-full animate-pulse bg-neutral-gray-light rounded-full'></div></td>
                        <td><div className='h-4 w-full animate-pulse bg-neutral-gray-light rounded-full'></div></td>
                        <td><div className='h-4 w-full animate-pulse bg-neutral-gray-light rounded-full'></div></td>
                    </tr>
                    <tr>
                        <td><div className='h-4 w-full animate-pulse bg-neutral-gray-light rounded-full'></div></td>
                        <td><div className='h-4 w-full animate-pulse bg-neutral-gray-light rounded-full'></div></td>
                        <td><div className='h-4 w-full animate-pulse bg-neutral-gray-light rounded-full'></div></td>
                        <td><div className='h-4 w-full animate-pulse bg-neutral-gray-light rounded-full'></div></td>
                    </tr>

                    <tr>
                        <td><div className='h-4 w-full animate-pulse bg-neutral-gray-light rounded-full'></div></td>
                        <td><div className='h-4 w-full animate-pulse bg-neutral-gray-light rounded-full'></div></td>
                        <td><div className='h-4 w-full animate-pulse bg-neutral-gray-light rounded-full'></div></td>
                        <td><div className='h-4 w-full animate-pulse bg-neutral-gray-light rounded-full'></div></td>
                    </tr>
                    <tr>
                        <td><div className='h-4 w-full animate-pulse bg-neutral-gray-light rounded-full'></div></td>
                        <td><div className='h-4 w-full animate-pulse bg-neutral-gray-light rounded-full'></div></td>
                        <td><div className='h-4 w-full animate-pulse bg-neutral-gray-light rounded-full'></div></td>
                        <td><div className='h-4 w-full animate-pulse bg-neutral-gray-light rounded-full'></div></td>
                    </tr>
                    <tr>
                        <td><div className='h-4 w-full animate-pulse bg-neutral-gray-light rounded-full'></div></td>
                        <td><div className='h-4 w-full animate-pulse bg-neutral-gray-light rounded-full'></div></td>
                        <td><div className='h-4 w-full animate-pulse bg-neutral-gray-light rounded-full'></div></td>
                        <td><div className='h-4 w-full animate-pulse bg-neutral-gray-light rounded-full'></div></td>
                    </tr>
                    <tr>
                        <td><div className='h-4 w-full animate-pulse bg-neutral-gray-light rounded-full'></div></td>
                        <td><div className='h-4 w-full animate-pulse bg-neutral-gray-light rounded-full'></div></td>
                        <td><div className='h-4 w-full animate-pulse bg-neutral-gray-light rounded-full'></div></td>
                        <td><div className='h-4 w-full animate-pulse bg-neutral-gray-light rounded-full'></div></td>
                    </tr>
                    <tr>
                        <td><div className='h-4 w-full animate-pulse bg-neutral-gray-light rounded-full'></div></td>
                        <td><div className='h-4 w-full animate-pulse bg-neutral-gray-light rounded-full'></div></td>
                        <td><div className='h-4 w-full animate-pulse bg-neutral-gray-light rounded-full'></div></td>
                        <td><div className='h-4 w-full animate-pulse bg-neutral-gray-light rounded-full'></div></td>
                    </tr>
                    <tr>
                        <td><div className='h-4 w-full animate-pulse bg-neutral-gray-light rounded-full'></div></td>
                        <td><div className='h-4 w-full animate-pulse bg-neutral-gray-light rounded-full'></div></td>
                        <td><div className='h-4 w-full animate-pulse bg-neutral-gray-light rounded-full'></div></td>
                        <td><div className='h-4 w-full animate-pulse bg-neutral-gray-light rounded-full'></div></td>
                    </tr>
                </tbody>
            </table>
        </>
    )
}

export default TableSkeleton