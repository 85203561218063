import React from 'react'
import "./HeroImage.css"
const HeroImage = () => {
    return (
        <div className="flex flex-wrap gap-4 justify-end mt-[20%] ml-24">
            <div className='relative h-[300px] w-[160px] rounded-[50px] image-1 bg-neutral-gray'></div>
            <div className='relative h-[300px] mt-[-135px] w-[160px] rounded-[50px] image-2 bg-neutral-gray'></div>
            <div className='relative h-[300px] w-[160px] rounded-[50px] image-3 bg-neutral-gray'></div>
            <div className='relative h-[300px] w-[160px] rounded-[50px] image-4 bg-neutral-gray'></div>
            <div className='relative h-[300px] mt-[-135px] w-[160px] rounded-[50px] image-5 bg-neutral-gray'></div>
            <div className='relative h-[300px] w-[160px] rounded-[50px] image-6 bg-neutral-gray'></div>
            <div className='relative h-[300px] w-[160px] rounded-[50px]'></div>
            {/* <div className='relative h-[300px] mt-[-135px] w-[160px] rounded-[50px] image-7 bg-neutral-gray'></div> */}
            <div className='relative h-[300px] w-[160px] rounded-[50px]'></div>
        </div>
    )
}

export default HeroImage